if (document !== window.document) {
	var _cruxUtils = _cruxUtils || { isLyteWidgetBuild: Lyte && Lyte.isWidgetBuild };//eslint-disable-line no-use-before-define
} else {
	var _cruxUtils = window._cruxUtils = window._cruxUtils || {};//eslint-disable-line no-redeclare
}
_cruxUtils.showCustomAlert = function(opt){

	if(opt.params && opt.params.ltPropWrapperClass){
		opt.params.ltPropWrapperClass = opt.params.ltPropWrapperClass+" "+'cxAlertWrapper';
	}else{
		opt.params.ltPropWrapperClass = 'cxAlertWrapper' //no i18n
	}
	var alert=$L('#cruxUtilAlert');
	var data={cxPropShow: true};
    for (key in opt.params)
    {
        if(opt.params.hasOwnProperty(key)){
        _key="cx"+key.substring(2);
        data[_key]=opt.params[key];
    }}
	data.cxPropUtilAlert=true;
	if(alert.length>0)
	{
		data = Object.assign({cxPropCloseOnEscape : true,cxPropSlideDown : "slideDown",cxPropContentAlign : "left",cxPropType : "", cxPropPrimaryMessage : "", cxPropSecondaryMessage : "",cxPropAllowMultiple : false,cxPropHeading : "",cxPropButtonPosition : 'center',cxPropTop : '0',cxPropShowCloseButton : false, cxPropYield: false,cxPropFooterYield: false, cxPropButtons : [{"type":"accept","text":_cruxUtils.getI18n('crm.mb.newversion.msg4'),"appearance":"primary"}] },data);//no i18n
		alert[0].setData(data);
	}
	else
	{
		alert.push(Lyte.Component.render("crux-alert",data,opt.params.ltPropOutputNode ? opt.params.ltPropOutputNode : 'body'));
		alert[0].id="cruxUtilAlert";
	}
    alert[0].setMethods({
        onShow : function(node){
            if(opt.show){
                opt.show(node);
            }
        }, 
        onAccept  : function (node,buttonText){ 
            if(opt.accept){
                opt.accept(node,buttonText); 
            }
        },
        onReject  : function (node,buttonText){ 
            if(opt.reject){
                opt.reject(node,buttonText)
            }
        },
		onClose  : function (node){ 
            if(opt.close){
                opt.close(node)
            }
        }
	});
}
_cruxUtils.removeCustomAlert = function(){
	if($L('crux-alert').length > 0){
		$L('crux-alert')[0].remove(); //no i18n
	}
}

_cruxUtils.showCustomMessage = function(opt){
	var data={cxPropShow: true};
	for (key in opt.params)
    {
        if(opt.params.hasOwnProperty(key)){
         _key="cx"+key.substring(2);
        data[_key]=opt.params[key];
    }}
	var checkMessageBox= document.getElementById("cruxCustomMessage");   //check for existing messagebox
	checkMessageBox && checkMessageBox.remove()
    var messageBox=Lyte.Component.render("crux-messagebox",data,'body')
	messageBox.id='cruxCustomMessage';
	messageBox.setMethods({ 
		onShow : function(node){
			if(opt.show){
				opt.show(node);
			}
		},
		onClose  : function (){ 
			if(opt.close){
				opt.close()
			}
			this.$node && this.$node.remove();
		} 
	
	});
}
_cruxUtils.removeCustomMessage = function(){
	if($L('crux-messagebox').length > 0){
		$L('crux-messagebox')[0].remove(); //no i18n
	}
}
_cruxUtils.showProfilePermissionModal =  function(opt){
	if(!opt){
		return
	}
	opt.params = opt.params ? opt.params :{} 
	var modalNode = $L('#cruxPermissionPopup')[0]; //no i18n
	if(modalNode){
		opt.params.cxPropSelectedIds = opt.params.cxPropSelectedIds ? opt.params.cxPropSelectedIds :[];
		modalNode.setData(opt.params);
	}else{
		opt.params.id = 'cruxPermissionPopup';
		modalNode=Lyte.Component.render('crux-profile-permission-modal',opt.params,"body");
	}
	modalNode.setMethods({
		onShow : function(){
			if(opt.onShow){
				opt.onShow(arguments[0]);
			}
		},onAddProfile : function (){ 
			if(opt.onAddProfile){
				opt.onAddProfile(arguments[0]); 
			}
		},onBeforeRemoveProfile : function (){ 
			if(opt.onBeforeRemoveProfile){
				opt.onBeforeRemoveProfile(arguments[0]); 
			}
		},onRemoveProfile :  function (){ 
			if(opt.onRemoveProfile){
				return opt.onRemoveProfile(arguments[0]); 
			}
		},onClose  : function (){ 
			if(opt.onClose){
				opt.onClose(arguments[0]);
			}
	 	},onBeforeSave  : function (){ 
			if(opt.onBeforeSave){
				return opt.onBeforeSave(arguments[0]);
			}
	 	}
	})
	modalNode.setData("cxPropShow",true)//no i18n
	
}

_cruxUtils.showSchedularNotification = function( opt ){
	// opt = {params : { cxPropHeader : "lead status" , cxPropMessage : "Lead conversion is progress ...." , cxPropStatus : "progress" } }
	// opt.params.cxPropLink = [{route : "crm.settings.index" , message : "settings" , class : "testClass"}]
	if(!opt){
		return
	}
	opt.params = opt.params ? opt.params :{} 
	opt.params.cxPropShow = opt.params.cxPropShow == undefined ? true : opt.params.cxPropShow;
	let cls = opt.params.cxPropClass ?  "_"+opt.params.cxPropClass : "";
	var schedularNode = $L('.cruxSchedularPopup'+cls)[0]; //no i18n
	if(schedularNode){
		if(opt.cxAddMessage && opt.params.cxPropMessage){
			Lyte.arrayUtils(schedularNode.getData('cxPropMessage'), 'insertAt', 0 ,opt.params.cxPropMessage ) //no i18n
			delete opt.params.cxPropMessage;
		}
		schedularNode.setData(opt.params);
	}else{
		opt.params.id = 'cruxSchedularPopup';
		opt.params.class = 'cruxSchedularPopup'+cls;
		schedularNode=Lyte.Component.render('crux-schedular-notification-popup',opt.params,"body");
	}
	schedularNode.setMethods({
		onShow : function(){
			
			if(opt.onShow){
				opt.onShow(arguments[0]);
			}
		},onClose  : function (comp , calledFrom){ 
			if(opt.onClose){
				opt.onClose(comp,calledFrom);
			}
			if( calledFrom == 'close'){
				this.$node && this.$node.remove();
			}
	 	}
	})
	// schedularNode.setData("cxPropShow",true)//no i18n
}
_cruxUtils.getSchedularNotificationData = function(){
	var schedularNodes = $L('crux-schedular-notification-popup');
	var ind = 0 , len = schedularNodes.length , res = [] , item;
	for(ind = 0 ; ind < len ; ind++){
		item = schedularNodes[ ind ];
		var data = item.getData();
		res.push( { class : data.cxPropClass,header : data.cxPropHeader , messages : data.cxPropMessage , link : data.cxPropLink ,status :data.cxPropStatus } )
	}
	return res;
}
_cruxUtils.removeSchedularNotification = function(){
	var node = $L('crux-schedular-notification-popup')[0]
	if(node){
		node.remove();
	}
}
/**
 * Use this util to render lookup modal directly instead of using the crux lookup component.
 */
_cruxUtils.showLookupModal = function(op){
	var ele = document.querySelector("crux-lookup-modal");
	if(!ele){
		ele = Lyte.Component.render("crux-lookup-modal", {}, op.body ? op.body : "body");		
	}
	if(!op.data){
		op.data = {};
	}
	/**
	 * properties to set to the modal
	 */
	var toSet = ["cxPropFields", "cxPropMetaMoreRecords", "fieldMapping", "cxPropValue", "cxPropShowNavigator", "cxPropReturnFullObjectOnGet", "cxPropShowOnlyIcon",
		"cxPropHideHeaderOnNoContent", "cxPropModalMiddleText"];
	var data = {};
	toSet.forEach(function(key){
		data[key] = op.data[key];
	})
	ele.setData(data);
	if(!op.methods){
		op.methods = {};
	}
	ele.setMethods({
		fetchModuleData : op.methods.fetchModuleData,
		fetchRecords : op.methods.fetchRecords,
		onValueChange : op.methods.onValueChange
	})
	ele.component.lookupInit(ele.component,op.data.cxPropField.lookup.module.id,true);
	/*
	isSingle : true, renderLookup : true, dispInit : true
	if(this.getMethods("onBeforeShowLookup")){
			var res = this.executeMethod("onBeforeShowLookup");
			if(res == false){
				return false;
			}
		}
	    if(typeof commonUtils != "undefined" && commonUtils.showHideLoadingDiv){
			commonUtils.showHideLoadingDiv(true);
		}
		*/
}
_cruxUtils.showPermissionDeniedModal = function(opt){
	var comp = $L("crux-permission-denied-alert")[0];
	if(comp){
		comp.remove();
	}
	var component = Lyte.Component.render("crux-permission-denied-alert",opt?opt.params:opt,"body");
	if(component){
		component.cxProp('show',true);
		component.setMethods({
			onShow : function(){
				if(opt.show){
					opt.show();
				}
			}, 
			onAccept  : function (){ 
				if(opt.accept){
					opt.accept(); 
				}
			},
			onReject  : function (){ 
				if(opt.reject){
					opt.reject()
				}
			},
			onClose  : function (){ 
				if(opt.close){
					opt.close()
				}
			}
		});
	}
}
window._cruxUtils.version = "3.143.0-crossFilter-2";
