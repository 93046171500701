Lyte.Component.register("crux-two-column", {
_template:"<template tag-name=\"crux-two-column\"> <div> <ul id=\"twoColumnAvailable\" class=\"twoColumnSort\"> <template is=\"for\" items=\"{{availableList}}\" item=\"item\" index=\"index\"> <li id=\"{{item[cxPropSystemValue]}}\" class=\"sd\">{{item[cxPropUserValue]}}</li> </template> </ul> </div> <br> <br> <br> <br> <div> <ul id=\"twoColumnSelected\" class=\"twoColumnSort\"> <template is=\"for\" items=\"{{selectedList}}\" item=\"item\" index=\"index\"> <li id=\"{{item[cxPropSystemValue]}}\">{{item[cxPropUserValue]}}</li> </template> </ul> </div> </template>",
_dynamicNodes : [{"type":"attr","position":[1,1,1]},{"type":"for","position":[1,1,1],"dynamicNodes":[{"type":"attr","position":[1]},{"type":"text","position":[1,0]}]},{"type":"attr","position":[11,1,1]},{"type":"for","position":[11,1,1],"dynamicNodes":[{"type":"attr","position":[1]},{"type":"text","position":[1,0]}]}],
_observedAttributes :["cxPropList","cxPropType","cxPropVisibleKey","cxPropSelectedList","cxPropUserValue","cxPropSystemValue","availableList","selectedList","cxPropSequencing","cxPropSequenceKey"], //no i18n
	data : function(){
		return {
			cxPropList : Lyte.attr('array',{default : []}), //no i18n
			cxPropType : Lyte.attr('string',{default : 'visible'}), //no i18n
			cxPropVisibleKey : Lyte.attr('string',{default : 'visible'}), //no i18n
			cxPropSelectedList : Lyte.attr('array',{default : []}), //no i18n
			cxPropUserValue : Lyte.attr('string'), //no i18n
			cxPropSystemValue : Lyte.attr('string'), //no i18n
			availableList : Lyte.attr('array',{default : []}), //no i18n
			selectedList : Lyte.attr('array',{default : []}), //no i18n
			cxPropSequencing : Lyte.attr('boolean',{default : true}), //no i18n
			cxPropSequenceKey : Lyte.attr('string',{default : 'sequence_number'}) //no i18n
		}		
	},
	init : function(){
		this.$node.getList = function(){
			return this.component.getList();
		}
		this.$node.resetList = function(){
			this.listMake();
		}
		this.listMake();
	},
	didConnect : function(){
		this.makeSortable();
	},
	getList : function(){
		if(this.getData('cxPropType') == 'visible'){
			var list=[].concat(this.getData('selectedList')).concat(this.getData('availableList')); //no i18n
			if(this.getData('cxPropSequencing')){
				var listLength = list.length;
				for(var i=0;i<listLength;i++){
					list[i][this.getData('cxPropSequenceKey')] = i+1;
				}
			}
			return list;
		}else{
			var list=[].concat(this.getData('selectedList')); //no i18n
			if(this.getData('cxPropSequencing')){
				var listLength = list.length;
				for(var i=0;i<listLength;i++){
					list[i][this.getData('cxPropSequenceKey')] = i+1;
				}
			}
			return list;
		}

	},
	listMake : function(){
		var availableList=[],selectedList=[];
		var list=[].concat(this.getData('cxPropList')); //no i18n
		var listLength = this.getData('cxPropList').length; //no i18n
		if(this.getData('cxPropType') == 'visible'){
			for(var i=0;i<listLength;i++){
				if(this.getData('cxPropList')[i][this.getData('cxPropVisibleKey')] == false){
					availableList.push(list[i]);
				}else{
					selectedList.push(list[i]);
				}
			}
		}else{
			var selectedList = [].concat(this.getData('cxPropSelectedList')); //no i18n
			var selLength = selectedList.length;
			for(var i=0;i<listLength;i++){
				var flag=0;
				for(var j=0;j<selLength;j++){
					if(selectedList[j][this.getData('cxPropSystemValue')] == list[i][this.getData('cxPropSystemValue')]){
						flag=1;
						break;
					}
				}
				if(flag == 0){
					availableList.push(list[i]);
				}
			}
		}
		this.setData('availableList',availableList); //no i18n
		this.setData('selectedList',selectedList); //no i18n
	},
	makeSortable : function(){
		$L(".twoColumnSort").sortable({  //no i18n
			connectedWith  : ".twoColumnSort", //no i18n
			restrict  : ".restrictItem", //no i18n
			onDrop  : function (droppedElement ,destinantion ,belowElement ,fromIndex ,toIndex ,source ){
				if(this.getData('cxPropType') == 'visible'){
					if(destinantion.id == 'twoColumnAvailable' && source.id == 'twoColumnSelected'){
						var obj = this.getData('selectedList').filterBy({id : droppedElement.id})[0];
						obj[this.getData('cxPropVisibleKey')] = false;
						Lyte.arrayUtils(this.getData('selectedList'),'removeAt',fromIndex); //no i18n
						Lyte.arrayUtils(this.getData('availableList'),'insertAt',toIndex,obj); //no i18n
					}else if(destinantion.id == 'twoColumnSelected' && source.id == 'twoColumnAvailable'){
						var obj = this.getData('availableList').filterBy({id : droppedElement.id})[0];
						obj[this.getData('cxPropVisibleKey')] = false;
						Lyte.arrayUtils(this.getData('availableList'),'removeAt',fromIndex); //no i18n
						Lyte.arrayUtils(this.getData('selectedList'),'insertAt',toIndex,obj); //no i18n
					}else{
						if(destinantion.id == 'twoColumnAvailable'){
							var obj = this.getData('availableList').filterBy({id : droppedElement.id})[0];
							Lyte.arrayUtils(this.getData('availableList'),'removeAt',fromIndex); //no i18n
							Lyte.arrayUtils(this.getData('availableList'),'insertAt',toIndex,obj); //no i18n
						}else{
							var obj = this.getData('selectedList').filterBy({id : droppedElement.id})[0];
							Lyte.arrayUtils(this.getData('selectedList'),'removeAt',fromIndex); //no i18n
							Lyte.arrayUtils(this.getData('selectedList'),'insertAt',toIndex,obj); //no i18n
						}
					}
				}
				this.makeSortable();
		 	}.bind(this),
		 	onPlaceholder  : function (draggableElement ,placeholderElement ,source ,destination ){ 
				if(draggableElement.parentElement.id == 'twoColumnAvailable' && placeholderElement.parentElement.id == 'twoColumnAvailable'){
					return false;
				}
				return true;
		 	}
	 	});
	},
	listObserver : function(){
		this.listMake();
		this.makeSortable();
	}.observes('cxPropList.[],cxPropSelectedList.[]') //no i18n
});
